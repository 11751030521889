import { HttpClient, HttpErrorResponse, HttpResponse, HttpResponseBase } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Apollo } from "apollo-angular";
import { catchError, Observable, of, retry, switchMap, timeout } from "rxjs";

export const PIM_API_BASE_URL: InjectionToken<string> = new InjectionToken("PIM_API_BASE_URL");

@Injectable({
    providedIn: "root"
})
export class PimApiClient {
    constructor(
        @Inject(HttpClient) private readonly http: HttpClient,
        @Inject(PIM_API_BASE_URL) private readonly baseUrl: string,
        @Inject(Apollo) private readonly apollo: Apollo
    ) {
    }

    public GetGraphqlQuery(): Apollo {
         return this.apollo;
    }

    public Import(file: File): Observable<null> {
        const formData = new FormData();
        formData.set("file", file, file.name);

        return this.http.post<null>(`${this.baseUrl}/V1/Importers/`, formData, {
            responseType: "json",
            observe: "response",
        }).pipe(timeout(600_000), switchMap((response) => this.handleStatus<null>(response)), catchError((error) => this.handleStatus<null>(error)));
        // Timeout for 10 minutes
    }

    public ExportVendit(): Observable<Blob | null> {
        return this.http.get(`${this.baseUrl}/V1/Exporters/Vendit`, {
            responseType: "blob",
            observe: "response",
        }).pipe(retry(3), switchMap((response) => this.handleStatus<Blob>(response)), catchError((error) => this.handleStatus<null>(error)));
    }

    private handleStatus<T>(response: HttpResponseBase): Observable<T | null> {
        if (response instanceof HttpResponse) {
            if (response.ok) {
                return of(response.body as T);
            }
        }

        let body: string = "";
        if (response instanceof HttpResponse) {
            body = response.body as string;
        } else if (response instanceof HttpErrorResponse) {
            body = response.error as string;
        } else {
            throw new Error("Unknown response type");
        }

        switch (response.status) {
            case 400:
                throw new Error(body);
            case 401:
                throw new Error("Unauthorized");
            case 403:
                throw new Error("Forbidden");
            case 404:
                throw new Error("Not Found");
            case 409:
                throw new Error("Conflict");
            case 500:
                throw new Error("Internal Server Error");
            default:
                throw new Error(`Unknown error: ${response.status.toString()}`);
        }
    }
}
